/* Upload module for truview site
 * Copyright Leica Geosystems (c) 2015 All rights reserved
 * ===============================================================================
 */

/// <reference path='../../../node_modules/resumablejs/resumable.d.ts' />

// ===============================================================================

import $ from 'jquery';
import * as _ from 'lodash';
import * as Q from 'q';
import Resumable from 'resumablejs';

/**
 * @internal
 */
export interface UploaderOptions {
  dropElementId: string;
  browseElementId: string;
  name: string;
  userId: string;
  dict: any;
  chunksize?: number;
  acceptExtensions?: string[];
  controls? : {
    pause: string;
    start: string;
    retry: string;
  };
  trackGlobalProgress: boolean;
  renderFileProgress: boolean;
  endPoints: {
    target: string;
    finalize: string;
  };
  acceptMultipleFiles: boolean;
}

/**
 *
 */
interface UploadCallback {
  ( res: any ) : void;
}


/**
 *
 */
interface EventCallbacks {
  [ eventName: string ] : UploadCallback[];
}
var failedUploads: Resumable.ResumableFile[] = [];
var uploader: Resumable;
var retryUpload: boolean = false;
var totCounter = 0;
var failCounter = 0;
var succCounter = 0;
var retryCounter = 0;
var pageIsUploading = false;
var opts;
var events : EventCallbacks = {
  'complete' : [],
  'start': [],
  'pause': [],
  'progress': [],
  'fail': [],
  'filecomplete': [],
  'filefail': [],
  'fileprogress': []
};


/**
  * Add a calback to be called when the upload is complete.
 * @internal
 */
export function onFail( callback: UploadCallback ) {
  events['fail'].push(callback);
}
/**
  * Add a calback to be called when the upload is complete.
 * @internal
 */
export function onFileComplete( callback: UploadCallback ) {
  events['filecomplete'].push(callback);
}
/**
 * Add a calback to be called when the upload is complete.
 * @internal
 */
export function onFileFail( callback: UploadCallback ) {
  events['filefail'].push(callback);
}

/**
 * Add a calback to be called when the upload is complete.
 * @internal
 */
export function onComplete( callback: UploadCallback ) {
  events['complete'].push(callback);
}

/**
 * Add a calback to be called when the upload is complete.
 * @internal
 */
export function onProgress( callback: UploadCallback ) {
  events['progress'].push(callback);
}

/**
 * Add a calback to be called when the upload starts.
 * @internal
 */
export function onStart( callback: UploadCallback ) {
  events['start'].push(callback);
}

/**
 * Add a calback to be called when the upload starts.
 * @internal
 */
export function onPause( callback: UploadCallback ) {
  events['pause'].push(callback);
}

/**
 * Add a calback to be called on single file progress.
 * @internal
 */
export function onFileProgress( callback: UploadCallback ) {
  events['fileprogress'].push(callback);
}

/**
 * Call all the callbacks linked to the given event
 * @internal
 */
function triggerCallbacks( eventName: string, p: any, e?: Error ) {
  var cbs = events[eventName];
  if ( cbs ) {
    _.each( cbs, (cb: UploadCallback ) => cb(e || p) );
  }
}

/**
 * start uploading
 */
function start(r: Resumable) {
  r.upload();
}


/**
 * internal
 */
export function resetEvents() {
  events = {
    'complete' : [],
    'start': [],
    'pause': [],
    'progress': [],
    'fail': [],
    'filecomplete': [],
    'filefail': [],
    'fileprogress': []
  };
}

/**
 * Reset progress, stats
 *
 * @internal
 */
export function reset() {
  if ( uploader ) {
    uploader.cancel();
    totCounter = 0;
    failCounter = 0;
    succCounter = 0;
    pageIsUploading = false;
    _.forEach( failedUploads, fu => uploader.removeFile(fu) );
    failedUploads = [];
    $(`#${opts.dropElementId} > p`).remove();
  }
}

/**
 * Reset progress, stats and events
 *
 * @internal
 */
export function resetAll() {
  reset();
  resetEvents();
}


/**
 *
 * @internal
 */
export function pauseUpload() {
  if (  uploader ) {
    uploader.pause();
    triggerCallbacks('pause', 1 );
  }
}

/**
 *
 * @internal
 */
export function startUpload() {
  if ( uploader ) {
    start(uploader);
    triggerCallbacks('start', 1 ); // It should send the number of file in the queue
  }
}

/**
 *
 * @internal
 */
export function getTotalCounter(): number { return totCounter; }

/**
 *
 * @internal
 */
export function getTotalSuccess(): number { return succCounter; }

/**
 *
 * @internal
 */
export function getTotalFails(): number { return failCounter; }

export interface FinalizeResult {
  path: string;
  name: string;
}

// -------------------------------------------------------------------------------
function finalize(
  dict:any,
  targetUrl: string,
  userid: string,
  id: string,
  fileName: string,
  renderFileProgress: boolean,
  later: Q.Deferred<FinalizeResult> = null ) : Q.Promise<FinalizeResult> {
  var loclater = later || Q.defer<FinalizeResult>();
  var succeded = false;
  var finalizeUrl = `${targetUrl}?userid=${userid}&id=${id}&fname=${encodeURIComponent(fileName)}`;
  var opt: JQueryAjaxSettings = {
    url: finalizeUrl,
    timeout: 30000, // 30 seconds timeout
    type: 'PATCH',
    contentType: 'application/json; charset=utf-8',
    headers: { accept: 'application/json' }
    // data: JSON.stringify(view)
  };
  $.ajax(opt)
  .done( (res: any) => {
    succeded = true;
    succCounter++;
    if ( renderFileProgress )
      $('#' + id).html(getEntry(dict, fileName, 'complete'));
    loclater.resolve( { path: res.path, name: fileName } );
  })
  .fail( ( err ) => {
    // If the call failed because of timeout call it again so to wait unitil finalize completes
    if ( err.statusText === 'timeout' ) {
      _.defer( () => finalize( dict, targetUrl, userid, id, fileName, renderFileProgress, loclater) );
    }
    else {
      failCounter++;
      var message = err.responseText;
      if ( err.responseJSON ) {
        message = err.responseJSON.error.message;
      }
      if ( renderFileProgress )
        $('#' + id).html(getEntry( dict, fileName, 'finalizeError', message));
      loclater.reject(new Error(`Finlizing ${fileName} failed - ${message}`));
    }
  });
  /*
  .always(() => {
    // updateCounters(totCounter, succCounter, failCounter);
  });
  */
  return loclater.promise;
}

// -------------------------------------------------------------------------------
function getEntry( dict: any, name: string, phase: string, progress?: number | string ) : string {
  var inProgress = '<span class="label label-primary"><i class="fa fa-upload"></i></span>';
  var isUploaded = '<span class="label label-primary"><i class="fa fa-check"></i></span>';
  var isComplete = '<span class="label label-success"><i class="fa fa-check"></i></span>';
  var hasFailed = '<span class="label label-danger"><i class="fa fa-warning"></i></span>';
  var hasSucceeded = '<span class="label label-success">100.0%</span>';
  var isFinalizing = `<span class="label label-primary"> <i class="fa fa-spinner fa-pulse fa-lg"></i> ${dict.finalizing} </span>`;
  var fileProg = '0.00';
  if ( typeof progress === 'number' )
    fileProg = progress.toFixed(2);

  switch( phase ) {
    case 'ready':
      return `${inProgress} <mark>${name}</mark> ${dict.ready}`;
    case 'uploading':
      return `${inProgress} <mark>${name}</mark> ${dict.fileuploading} <span class="label label-primary">${fileProg}%</span> ${dict.filecomplete} ...`;
    case 'uploadfailed':
      return `${hasFailed} <mark>${name}</mark> ${dict.fileuploading} <span class="label label-danger">Error</span> : <code>${progress}</code>`;
    case 'retrying':
      return `<mark>${name}</mark> ${dict.fileuploading} <span class="label label-warning">${dict.retrying}...</span> : ${dict.lostchunk} ...`;
    case 'finalizing':
      return `${isUploaded} <mark>${name}</mark> ${dict.fileuplcomplete}. ${isFinalizing} ...`;
    case 'finalizeError':
      return `${hasFailed} <mark>${name}</mark> ${dict.fileuplcomplete}. <span class="label label-danger">Finalize Error</span> : <code>${progress}</code>`;
    case 'complete':
      return `${isComplete} <mark>${name}</mark> ${hasSucceeded} ${dict.filecomplete}. ${dict.dataready}.`;
  }
}

/**
  * Upload messages to report new counter values.
  */
/*
function updateCounters( tot: number, succ: number, fail: number ) {
  var updateInfo = `Upload Completed: <span class="label label-success">${succ}</span> / <span class="label label-primary">${tot}</span>`;
  updateInfo += ` - Failed <span class="label label-danger">${fail}</span>`;
  $('#resultInfo').html(updateInfo);
}*/


/**
 * Initialize an uploader
 * @internal
 */
export function init( options: UploaderOptions ) {
  totCounter = 0;
  failCounter = 0;
  succCounter = 0;
  retryCounter = 0;
  opts = options;

  if ( opts.acceptMultipleFiles === undefined )
    opts.acceptMultipleFiles = true;

  if ( opts.controls ) {
    $(`#${opts.controls.pause}`).attr('disabled', 'true');
    $(`#${opts.controls.start}`).attr('disabled', 'true');
    $(`#${opts.controls.retry}`).attr('disabled', 'true');
  }
  var r : Resumable.Resumable = new Resumable({
    target: opts.endPoints.target ,
    testChunks: true,
    prioritizeFirstAndLastChunk: false,
    simultaneousUploads: 4,
    chunkSize: options.chunksize || 40 * 1024 * 1024,
    query: { apikey: 'truview', username: opts.name, userid: opts.userId },
    maxFiles: ( opts.acceptMultipleFiles ) ? undefined : 1,
    headers: { Accept: '*/*' }
  });

  if (!r.support) {
    alert('Upload is not supported on this browser.');
    // TODO !!! not a compatible browser!
  }
  else {
    let lastError : Error;
    var fileInfoEl : JQuery = $('#' + opts.dropElementId);
    // Assigne the newly created resumable object to the moude active resumable
    uploader = r;
    failedUploads = [];
    r.assignBrowse(document.getElementById(opts.browseElementId), false);
    r.assignDrop(document.getElementById(opts.dropElementId));

    // Window page change event
    window.onbeforeunload = () => {
      if (pageIsUploading) {
        return 'Warning: change page will stop the upload and abort importing the data set. Are you sure ?';
      }
    };

    // Uploader events
    // ===========================================================================

    // new file added to the queue and ready for uploading.
    r.on('fileAdded',( queuedUpload: Resumable.ResumableFile) => {
      var fname = queuedUpload.file.name;
      if ( opts.acceptExtensions && opts.acceptExtensions.length > 0 ) {
        const fnameExtension = fname.substr( fname.lastIndexOf('.') ).toLowerCase();
        if ( _.findIndex( opts.acceptExtensions, (ext) => ext === fnameExtension ) === -1 ) {
          r.removeFile(queuedUpload);
          alert(`Can only accept files with these extensions: ${opts.acceptExtensions.toString()}`);
          return;
        }
      }
      if ( !opts.acceptMultipleFiles ) {
        if ( options.renderFileProgress ) {
          fileInfoEl.html('');
        }
        failCounter = 0;
        failedUploads = [];
      }

      /*
      if ( queuedUpload.size > 4000000000*4 ) {   // Max 12GB
        r.removeFile(queuedUpload);
        alert('File too big. 12GB Max allowed');
        return;
      }*/
      if ( options.renderFileProgress ) {
        fileInfoEl.append(`<p id="${queuedUpload.uniqueIdentifier}">${getEntry( opts.dict,queuedUpload.file.name,'ready')}</p>`);
      }
      totCounter++;
      // updateCounters(totCounter,succCounter,failCounter);

      triggerCallbacks('start', queuedUpload.file.name );
      if (!r.isUploading()) {
        _.delay( () => {
          start(r);
          if ( opts.controls ) {
            $(`#${opts.controls.pause}`).removeAttr('disabled');
          }
          pageIsUploading = true;
        },1000);
      }
    });

    // single file upload progress UI update ...
    r.on('fileProgress',(queuedUpload: Resumable.ResumableFile) => {
      if (r.isUploading()) {
        const progress = (queuedUpload.progress(false) * 100);
        if ( options.renderFileProgress ) {
          var $infoItem = $('#' + queuedUpload.uniqueIdentifier);
          $infoItem.html(getEntry( opts.dict, queuedUpload.file.name, 'uploading' ,progress));
          $infoItem.get(0).scrollIntoView(false);
        }
        else {
          triggerCallbacks('fileprogress',{ progress: progress, name: queuedUpload.file.name });
        }
      }
    });

    // this is an error we cannot recover. We enable the retry button and let user decide
    r.on('fileError',(queuedUpload: Resumable.ResumableFile, message: string) => {
      failedUploads.push(queuedUpload);
      failCounter++;
      totCounter--;
      let result = { error: new Error('data error') };
      try {
        result = JSON.parse(message);
        if ( options.renderFileProgress )
          $('#' + queuedUpload.uniqueIdentifier).html(getEntry( opts.dict, queuedUpload.file.name,'uploadfailed', result.error.message));
      }
      catch (e) {
        if ( options.renderFileProgress )
          $('#' + queuedUpload.uniqueIdentifier).html(getEntry( opts.dict, queuedUpload.file.name,'uploadfailed', 'data error' ));
      }
      lastError = result.error;
      lastError['file'] = queuedUpload.file;
      if ( !opts.acceptMultipleFiles ) {
        triggerCallbacks('fail', null, lastError );
      }
      else {
        triggerCallbacks('filefail', { name: queuedUpload.file.name, error: lastError } );
      }
    });

    // a chumk failed. retry ...
    r.on('fileRetry',( queuedUpload: Resumable.ResumableFile ) => {
      retryCounter++;
      if ( retryCounter > 20 ) {
        queuedUpload.cancel();
        r.removeFile(queuedUpload);
        triggerCallbacks('filefail', { name: queuedUpload.file.name, error: 'To many retry' } );
        $('#' + queuedUpload.uniqueIdentifier).html(getEntry( opts.dict, queuedUpload.file.name,'uploadfailed', 'data error : too many retry' ));
      }
      else {
        $('#' + queuedUpload.uniqueIdentifier).html(getEntry( opts.dict, queuedUpload.file.name, 'retrying' ));
      }
    });

    // overall upload progress ...
    r.on('progress',() => {
      if ( options.trackGlobalProgress ) {
        retryUpload = false;
        var totProgress = (r.progress() * 100).toFixed(2);
        triggerCallbacks('progress',totProgress);
      }
    });

    // File Upload complete. Now let's Finalize it
    r.on('fileSuccess',(queuedUpload: Resumable.ResumableFile , message) => {
      if ( options.renderFileProgress )
        $('#' + queuedUpload.uniqueIdentifier).html(getEntry( opts.dict, queuedUpload.file.name, 'finalizing' ));
      finalize( opts.dict, opts.endPoints.finalize, opts.userId, queuedUpload.uniqueIdentifier, queuedUpload.file.name, options.renderFileProgress )
      .then( ( res: FinalizeResult ) => {
        triggerCallbacks('filecomplete', res );
      })
      .fail( (err: Error) => {
        failedUploads.push(queuedUpload);
        failCounter++;
        err['file'] = queuedUpload.file;
        if ( !opts.acceptMultipleFiles ) {
          triggerCallbacks('fail', null, err );
        }
        else {
          triggerCallbacks('filefail', null, err );
        }
      });
    });

    // All file uploaded succesfully.
    r.on('complete',() => {
      if (retryUpload) {
        return;
      }
      if ( opts.controls ) {
        $(`#${opts.controls.pause}`).attr('disabled', 'true');
        $(`#${opts.controls.start}`).attr('disabled', 'true');
        $(`#${opts.controls.retry}`).attr('disabled', 'true');
      }
      pageIsUploading = false;
      if (failedUploads.length > 0) {
        triggerCallbacks('fail',null, lastError );
        if ( opts.controls ) {
          $(`#${opts.controls.retry}`).removeAttr('disabled');
        }
      }
      triggerCallbacks('complete',totCounter );
    });

    // Toolbar button actions
    // ===========================================================================
    if ( opts.controls ) {
      $(`#${opts.controls.pause}`).click((e) => {
        r.pause();
        $(`#${opts.controls.pause}`).attr('disabled', 'true');
        $(`#${opts.controls.start}`).removeAttr('disabled');
        if ( options.renderFileProgress )
          $('#uploadDropTarget').removeClass('pulsate');
      });
      $(`#${opts.controls.start}`).click((e) => {
        r.upload();
        $(`#${opts.controls.pause}`).removeAttr('disabled');
        $(`#${opts.controls.start}`).attr('disabled', 'true');
        if ( options.renderFileProgress )
          $('#uploadDropTarget').addClass('pulsate');
      });
      $(`#${opts.controls.retry}`).click((e) => {
        r.pause();
        _.each(failedUploads,(f) => {
          f.retry();
        });
        failCounter -= failedUploads.length;
        retryUpload = true;
        failedUploads = []; // Reset the failed collection.
        start(r);
      });
    }
  }
}
