import * as site from '../common.ui/tvgsite';
import { Flash } from './Flash';
import React from 'react';
import { PortalRef } from '../tvBase';

export interface StoreRoot {
  user?: site.UserInfo;
  welcomeDict: site.LocaleDictionary;
  loginDict: site.LocaleDictionary;
  navbarDict: site.LocaleDictionary;
  footerDict: site.LocaleDictionary;
  viewerDict: site.LocaleDictionary;
  siteDict: site.LocaleDictionary;
  scansDict: site.LocaleDictionary;
  locationsDict: site.LocaleDictionary;
  allscansDict: site.LocaleDictionary;
  adminDict: site.LocaleDictionary;
  consentDict: site.LocaleDictionary;
  resetuserDict: site.LocaleDictionary;
  queueDict: site.LocaleDictionary;
  uploadDict: site.LocaleDictionary;
  adminappsAuthorizeDict: site.LocaleDictionary;
  userDict: site.LocaleDictionary;
  usersDict: site.LocaleDictionary;
  admincustomizeDict: site.LocaleDictionary;
  adminportalsDict: site.LocaleDictionary;
  adminbackupDict: site.LocaleDictionary;
  adminappsDict: site.LocaleDictionary;
  adminpublishDict: site.LocaleDictionary;
  adminlogsDict: site.LocaleDictionary;
  adminsmtpDict: site.LocaleDictionary;
  adminupdateDict: site.LocaleDictionary;
  adminlicensingDict: site.LocaleDictionary;
  languageCodes: any;
  portalsEnabled?: boolean;
  allowUpload: boolean;
  uploadPortals?: PortalRef[];
  version?: string;
  serverName?: string;
  license?: site.License;
  custom?: site.CustomSettings;
  serverUrl: string;
  locale: string;
  newVersionAvailable: any;
  logtime: any;
  fileStorage: any;
  scanCount: any;
  termsaccepted: boolean;
  needsconsent: boolean;
  buildrev?: string;
}

export class Store {

  root!: StoreRoot;

  update!: any;

  async fetchRoot() {
    this.root = await site.getRequest2('/initialdata');
    this.update();
  };
  
  flash = new Flash({
    storageKey: 'FLASH_KEY',
    timeout: -1,
  });

  /**
   * Call if explicitly disposing.
   */
  dispose = () => {
    this.flash.dispose();
  };
}

export interface StoreProps {
  store: Store;
}

export const StoreContext = React.createContext(null as Store);
